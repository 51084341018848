// ============================================
// Variablen
// ============================================

const app = document.getElementById('app');
const brand = document.getElementById('brand');
const type = document.getElementById('type');
const gender = document.getElementById('gender');
let brillenOutput = ``;
let brandSelected = 'all';
let typeSelected = 'all';
let genderSelected = 'all';
let brandList = "<option value='brandall'>-- alle --</option>";
let typeList = "<option value='typeall'>-- alle --</option>";
let genderList = "<option value='genderall'>-- alle --</option>";
let brillenFiltered = brillen;
let updatedData = [];







if (app) {

    // filter

    let filterItems = {
        brandFilter: 'brandall',
        typeFilter: 'typeall',
        genderFilter: 'genderall'
    }

    brand.addEventListener('change', () => {
        filterItems.brandFilter = brand.value.toLowerCase().split(' ').join('')
        checkForBrandBanner()
        filterGlasses()
        noResult()
    })
    type.addEventListener('change', () => {
        filterItems.typeFilter = type.value.toLowerCase().split(' ').join('')
        checkForBrandBanner()
        filterGlasses()
        noResult()
    })
    gender.addEventListener('change', () => {
        filterItems.genderFilter = gender.value.toLowerCase().split(' ').join('')
        checkForBrandBanner()
        filterGlasses()
        noResult()

    })

    // Brandbanner bei Suche ausblenden
    function checkForBrandBanner() {
        if (filterItems.genderFilter !== 'genderall' || filterItems.brandFilter !== 'brandall' || filterItems.typeFilter !== 'typeall') {
            console.log(filterItems);
			$('.brandbanner').removeClass('active')
			if (filterItems.brandFilter !== 'brandall') { 
				$('[data-brandbanner="'+ filterItems.brandFilter +'"]').addClass('active')
			}
			// if (filterItems.genderFilter === 'genderall' && filterItems.typeFilter === 'typeall') { 
			// 	$('[data-brandbanner="'+ filterItems.brandFilter +'"]').addClass('active')
			// }
        } else {
            $('.brandbanner').addClass('active')
        }
    }

    // Filterfunktion
    function filterGlasses() {
        $('.brille').removeClass('active')
        $('.' + filterItems.brandFilter + '.' + filterItems.genderFilter + '.' + filterItems.typeFilter).addClass('active');
    }

    // Aus jeder Brille und jedem Teaser HTML Elemente erstellen
    function generateImages() {
        brillenOhneGet.map(brille => {
            // wenn das Objekt ein Brille ist, erstelle ein Brillen Element
            if (brille.type) {
                let modelNr = brille.brilleFace;
                modelNr = brille.brilleFace.substring(0, brille.brilleFace.lastIndexOf('.')).replace(/^(.*[\\\/])/, '')
                let id = brille.brilleFace.substring(0, brille.brilleFace.lastIndexOf('.')).replace(/^(.*[\\\/])/, '')
                modelNr = modelNr.substring(0, modelNr.lastIndexOf('_')).replace(/^(.*[\\\/])/, '')
                brillenOutput += `
                <a class="active typeall genderall brandall brille ${brille.brand.toLowerCase().split(' ').join('')} ${brille.type.toLowerCase().split(' ').join('')} ${brille.gender.toLowerCase().split(' ').join('')}" data-brand="${brille.brand}" data-gender="${brille.gender}" data-type="${brille.type}">
                ${brille.gender === 'Kinder' ? '<img class="kidz" src="assets/img/kids.svg" alt="Kinderbrille" width="50">' : ''}
                <svg class="heart" data-id="${id}" onclick="updateLocalStorage('${id}', '${modelNr}', '${brille.brand}', '${brille.brilleProfile}')" xmlns="http://www.w3.org/2000/svg" width="29.35" height="28.125" viewBox="0 0 29.35 28.125">
                    <g transform="translate(-120.827 -1.688)">
                        <path class="heart-empty" data-name="heart-empty" d="M24.75,3.938h-.07A8,8,0,0,0,18,7.594a8,8,0,0,0-6.68-3.656h-.07a7.949,7.949,0,0,0-7.875,7.945,17.115,17.115,0,0,0,3.361,9.33C10.969,27,18,32.063,18,32.063S25.031,27,29.264,21.213a17.115,17.115,0,0,0,3.361-9.33A7.949,7.949,0,0,0,24.75,3.938Zm2.925,16.116A53.929,53.929,0,0,1,18,29.587a54.01,54.01,0,0,1-9.675-9.541,15.169,15.169,0,0,1-2.981-8.163,5.966,5.966,0,0,1,5.92-5.97h.063a5.9,5.9,0,0,1,2.89.759,6.144,6.144,0,0,1,2.137,2,1.975,1.975,0,0,0,3.3,0,6.205,6.205,0,0,1,2.138-2,5.9,5.9,0,0,1,2.89-.759h.063a5.966,5.966,0,0,1,5.92,5.97A15.361,15.361,0,0,1,27.675,20.053Z" transform="translate(117.452 -2.25)"></path>
                        <path class="heart-full" data-name="heart" d="M24.75,3.938h-.07A8,8,0,0,0,18,7.594a8,8,0,0,0-6.68-3.656h-.07a7.949,7.949,0,0,0-7.875,7.945,17.115,17.115,0,0,0,3.361,9.33C10.969,27,18,32.063,18,32.063S25.031,27,29.264,21.213a17.115,17.115,0,0,0,3.361-9.33A7.949,7.949,0,0,0,24.75,3.938Z" transform="translate(117.552 -2.25)"></path>
                    </g>
                    </svg>
                <div  onclick="openModalBrille('${brille.brilleFace}', '${modelNr}', '${brille.brilleProfile}', '${modelNr}-Seitenansicht', '${brille.logo}')" class="brillenImages">
                    <img class="brilleFace" src="${brille.brilleFace}">
                    <img class="brilleProfile" src="${brille.brilleProfile}">
                </div>
                    <img class="model--logo ${brille.brand.replace(' ', '').toLowerCase()}" src="${brille.logo}" alt="${brille.brand}" >    
                    <em class="model--nummer">${modelNr}</em>
                    </div>
                </a>`
            }
            // wenn das Objekt keine Brille ist, erzeuge Banner
            if (brille.brandbanner && brandSelected === 'all' && typeSelected === 'all' && genderSelected === 'all') {
                let brandBannerDynamicLink = brille.brandbanner.link + ".php";
                brandBannerDynamicLink += $_GET['client'] ? "?client=" + $_GET['client'] : "";
                brandBannerDynamicLink += $_GET['client'] ? "&" : "?";
                brandBannerDynamicLink += $_GET['brands'] ? "brands=" + $_GET['brands'] : "";


                brillenOutput += `
                    <div class="active brandbanner" data-brandbanner="${brille.brandbanner.brand.toLowerCase().split(' ').join('')}">
                        <div class="col-1 brand">
                            <img class="brandLogo ${brille.brandbanner.brand.toLowerCase().split(' ').join('')}" src="${brille.brandbanner.logo}">
                            <a class="button bgcolor" href="${brandBannerDynamicLink}">Mehr zur Marke</a></div><div class="col-1 ${brille.brandbanner.link}" style="background: url(${brille.brandbanner.image}) no-repeat; background-size: cover;"></div></div>`
            }
        })
    }

    // Meldung, falls kein Treffer

    function noResult() {
        if ($('#app').children(':visible').length == 0) {
            app.innerHTML = "<h2 id='noResult'>Ihre Suche ergab leider keinen Treffer</h2>"
        } else {
            app.innerHTML = brillenOutput
            checkForBrandBanner()
            filterGlasses()

        }
    }



    //LocalStorage

    function enableEmail() { 
        if ($('#favoriten-mailen')) { 
            const sendMail = $('#favoriten-mailen')
            sendMail.css({'opacity': 1, 'cursor': 'pointer', 'pointer-events': 'all'})
        }
    }

    function disableEmail() { 
        if ($('#favoriten-mailen')) { 
            const sendMail = $('#favoriten-mailen')
            sendMail.css({'opacity': 0.6, 'cursor': 'not-allowed', 'pointer-events': 'none'})
        }
    }

    $(function () {
        checkFavourites()
    })

    function checkFavourites() {
        if (loadFavourites().length !== 0) {
            // console.log('Favoriten');
            enableEmail()
            let favs = loadFavourites()
            let hearts = $('.heart-full')
            hearts.each(function () {
                $(this).removeClass('favourite')
            })
            // for (let i = 0; i < hearts.length; i++){
                //     // console.log(hearts[i]);
                //     hearts[i].removeClass('favourite')
                // }
                favs.map(f => {
                    let favId = f.id;
                    // console.log(document.querySelector('[data-id=' + favId + '] .heart-full'));
                    $('[data-id=' + favId + '] .heart-full').addClass('favourite');
                })
            } else { 
            // console.log('Kein Favoriten');
            disableEmail()
        }
    }
    function getUrlVars() {
        var vars = {};
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            vars[key] = value;
        });
        if (vars = {}) { 
            vars.client = 'marchon'
        }
        return vars;
    }

    
    let localStorageName = 'marchon-favourites';

    function saveFavourites(data) { 
        if (getUrlVars()['client'].length > 0) {
            localStorageName = 'favourites-' + getUrlVars()['client']
        }
       
        if (localStorage.getItem(localStorageName) <= 0) { 
            disableEmail()
        } else { 
            enableEmail()
        }

        localStorage.setItem(localStorageName, JSON.stringify(data))
    }
    function loadFavourites() { 
        if (getUrlVars()['client'].length > 0) {
            localStorageName = 'favourites-' + getUrlVars()['client']
        }
        if (localStorage.getItem(localStorageName) === 0) {
            disableEmail()
        } else { 
            enableEmail()
        }
        //console.log(localStorage.getItem(localStorageName));
        return JSON.parse(localStorage.getItem(localStorageName))
    }
    
    function updateLocalStorage(id, model, brand, image) {
        var data = loadFavourites();        
        if (data && data.length > 0) {
            var filteredData = data.filter(d => d.id !== id);
            if (data.length === filteredData.length) {
                data.push({
                    id, model, brand, image
                })
            } else { 
                data = filteredData
            }
            enableEmail()
        }
        else { 
            data = [{
                id,
                model,
                brand,
                image
            }]
            disableEmail()
        }    
        saveFavourites(data)
        checkFavourites()
     }


    generateImages()
    // DOM Elemente erzeugen

    app.innerHTML = brillenOutput


    // Markenliste für Option erstellen
    let brandsArray = brillenOhneGet.filter(ba => !ba.brandbanner)
    let brands = brandsArray.map(b => b.brand).sort()

    // Doppelte Marken auf einen Wert reduzieren
    let uniqueBrand = brands.reduce((a, b) => {
        if (a.indexOf(b) < 0) a.push(b);
        return a;
    }, []);
    let filteredOptions = uniqueBrand

    filteredOptions.map(key => brandList += `<option value="${key}">${key}</option>`)
    brand.innerHTML = brandList


    // Brillentyp für Option erstellen
    let types = brandsArray.map(t => t.type).sort()

    // Doppelte Marken auf einen Wert reduzieren
    let uniqueType = types.reduce((a, b) => {
        if (a.indexOf(b) < 0) a.push(b);
        return a;
    }, []);

    uniqueType.map(key => typeList += `<option>${key}</option>`)
    type.innerHTML = typeList



    // Geschlecht für Option erstellen
    let geschlecht = brandsArray.map(g => g.gender).sort()

    // Doppelte Marken auf einen Wert reduzieren
    let uniqueGender = geschlecht.reduce((a, b) => {
        if (a.indexOf(b) < 0) a.push(b);
        return a;
    }, []);

    uniqueGender.map(key => genderList += `<option>${key}</option>`)
    gender.innerHTML = genderList



}