var prev = $('.prev');
var next = $('.next');
var carouselItem = $('.carousel-item')





var counter = 0
prev.on('click', function () {
    carouselItem[counter].classList.remove('active')
    counter <= 0 ? counter = carouselItem.length - 1 : counter--
    carouselItem[counter].classList.add('active')
    console.log(counter)

})
next.on('click', function () {
    carouselItem[counter].classList.remove('active')
    counter === carouselItem.length - 1 ? counter = 0 : counter++
    carouselItem[counter].classList.add('active')
    console.log(counter)
})