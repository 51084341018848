if (document.getElementById("video")) {
    let myVideo = document.getElementById("video")
    const brandThumbs = document.querySelector('.brandThumbs')
    const muteButton = document.getElementById('mute')
    const pauseButton = document.getElementById('pause')
    let isMuted = false;
    let isPaused = false;

    function playPause() {

        isPaused = !isPaused


        isPaused ? pauseButton.src = 'assets/img/play.svg' : pauseButton.src = 'assets/img/pause.svg'


        console.log(isPaused)

        if (myVideo.paused) {
            myVideo.play();
        }
        else {
            myVideo.pause();

        }

    }

    const mute = () => {
        isMuted = !isMuted
        myVideo.muted = !myVideo.muted
        console.log(muteButton.src)
        isMuted === false ? muteButton.src = 'assets/img/audio.svg' : muteButton.src = 'assets/img/muted.svg'


    }

    (function () {
        myVideo && window.getComputedStyle(brandThumbs).display !== 'none' ? myVideo.play() : myVideo.paused
        myVideo ? myVideo.addEventListener('click', playPause) : null
    })()

    muteButton.addEventListener('click', () => {
        mute();
    })
}




