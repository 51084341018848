let model = $('.modelNr');
let brille = $('.brille');
const info = $('#info');
const info2 = $('#info2');
const close = $('#close');
const popup = $('#popup.base')
const popupMsg = $('#popup.msg')

const sendMail = $('#favoriten-mailen')
const modalInput = document.getElementById('input')
const modalThumbs = document.querySelectorAll('.modal__image-thumb')

function openModalBrille(brille, name, side, sidename, logo) {
    popup.addClass('active');
    modalInput.innerHTML = `
        <img src="${logo}" class="modal__brand-logo"> 
        <h3>– ${name} –</h3>
        <img class="modal__image-large" src="${brille}" alt="${name}">
        <div class="modal__image-thumbs">
            <img onclick="showImage(this, '${brille}')" class="modal__image-thumb active" src="${brille}" alt="${name}">
            <img onclick="showImage(this, '${side}')" class="modal__image-thumb" src="${side}" alt="${sidename}">
        </div>
    `
}

const showImage = (el, image) => { 
    const mainImage = document.querySelector('#input .modal__image-large')
    mainImage.src = image
    $('.modal__image-thumb').removeClass('active')
    el.classList.add('active')
}



info.on('click', function () {
    popup.addClass('active')
    $('#input').html(popupContent.info)
})

sendMail.on('click', function () {
    const data = loadFavourites()
    if (data.length <= 0) { 
        return
    }
    popup.addClass('active')
    let brillen = "";
    for (let i = 0; i < data.length; i++) { 
        let brandUrl = data[i].brand.replace(' ', '').toLowerCase()
        brillen += `
        <input type="hidden" name="model${i}" value="
            <img width='250' class='model-modell' src='https://marchon-online-katalog.de/${data[i].image}'/></td></tr><tr><td valign='top' align='center' class='leftColumnContent'><strong style='font-family: Arimo, sans-serif; font-weight: bold; text-transform: uppercase; letter-spacing: 2px;'>${data[i].brand}</strong><br/><em class='model--nummer' style='display: block; width: 100%; text-align: center'>${data[i].model}</em></td></tr></table></td>">`

    }
    let client = "";
    $_GET['client'] ? client = '?client=' + $_GET['client'] + '&' : client = "";
    $_GET['brands'] ? client += 'brands=' + $_GET['brands'] : client += "";

    const output = `
        <form action="email.php${client}" method="POST" id="mail-form">
            <h2>Favoriten senden</h2>
            <p>Geben Sie Ihre E-Mail Adresse ein und wir senden Ihnen Ihre Favoriten per Mail zu.</p>
            ${brillen}
			<input type="email" name="email" placeholder="E-Mail" required>
            <button><span class="button bgcolor">Favoriten Senden</span><span class="button-span"><img src="assets/img/mail-2.svg"></span></button><br><br>
            <em>Die Eingabe Ihrer E Mail Adresse dient lediglich dazu, Ihnen Ihre ausgewählten Favoriten per E-Mail zusenden. Es erfolgt keine Speicherung Ihrer Daten.</em>
        </form>`

    $('#input').html(output)
})
info2.on('click', function () {
    popup.addClass('active')
    $('#input').html(popupContent.info)
})

close.on('click', function () {
    popup.removeClass('active')
    popupMsg.removeClass('active')
    $('#input').html('')
})

window.addEventListener('keyup', e => e.keyCode === 27 && popup.removeClass('active'));



const popupContent = {
    info: `
    <h2>So können Sie unsere Brillen online anschauen</h2>

    <p>In unserem Katalog finden Sie einige Brillen aus der großen Auswahl, mit der wir Sie in unserem Fachgeschäft begeistern. <br><br>

    Sie können sowohl nach Marken, Korrektions- oder Sonnenbrillen (Art) als auch nach der Zielgruppe (Für) filtern. <br><br>

    Wenn Sie sich über eine unserer Marken mehr informieren möchten, klicken Sie einfach bei einem der Markenbanner auf „Mehr zur Marke“.
    </p>
    <h2 style="margin-top: 50px">Favoriten gefunden?</h2>
    <p>
    Sie haben sich gleich in ein Modell verliebt? Oder sogar in mehrere? Wunderbar, denn mit der Favoritenfunktion können Sie Ihr Herz so oft verschenken, wie Sie wollen.<br><br>
    Einfach nur rechts oben auf das kleine Herzchen klicken und schon wird das Modell unter Ihren Favoriten gespeichert. Sehr gern können Sie sich alle Ihre Favoriten auch per Mail zusenden lassen. So können Sie ganz in Ruhe Ihre Entscheidung für Ihre auserwählte Brille oder Brillen treffen. Und eventuell gleich ein Foto bei Ihrem Besuch in unserem Fachgeschäft mitbringen! So finden Sie und Ihre neue Brille noch schneller zusammen. Einfach dafür Ihre Mailadresse eingeben und die Favoriten auswählen!
    </p><br><img src="assets/img/mail.svg" alt="E-Mail">`,
    formEmail: `<form action="email.php" method="POST" id="mail-form">
    <h2>Favoriten senden</h2>
    <p>Geben Sie Ihre E-Mail Adresse ein und wir senden Ihnen Ihre Favoriten per Mail zu.</p>
    <input type="email" placeholder="E-Mail">
    <button class="button">Favoriten Senden</button>
  </form>`
}