const dateasd = [
    {
        brilleFace: "assets/img/longchamp/produkte/LO2127_Face-035.jpg",
        brilleProfile: "assets/img/longchamp/produkte/LO2127_Profile-035.jpg",
        logo: "assets/img/longchamp/longchamp.svg",
        brand: "Longchamp",
        type: "Korrektionsbrille",
        gender: "Damen",
    },
    {
        brilleFace: "assets/img/longchamp/LO2109_Face-717.jpg",
        brilleProfile: "assets/img/longchamp/LO2109_Profile-717.jpg",
        logo: "assets/img/longchamp/longchamp.svg",
        brand: "Longchamp",
        type: "Korrektionsbrille",
        gender: "Damen",
    },
    {
        brilleFace: "assets/img/longchamp/produkte/LO2660_Face-035.jpg",
        brilleProfile: "assets/img/longchamp/produkte/LO2660_Profile-035.jpg",
        logo: "assets/img/longchamp/longchamp.svg",
        brand: "Longchamp",
        type: "Korrektionsbrille",
        gender: "Damen",
    },
    {
        brilleFace: "assets/img/longchamp/LO2659_Face-214.jpg",
        brilleProfile: "assets/img/longchamp/LO2659_Profile-214.jpg",
        logo: "assets/img/longchamp/longchamp.svg",
        brand: "Longchamp",
        type: "Korrektionsbrille",
        gender: "Damen",
    },
    {
        brilleFace: "assets/img/longchamp/LO2608_Face-690.jpg",
        brilleProfile: "assets/img/longchamp/LO2608_Profile-690.jpg",
        logo: "assets/img/longchamp/longchamp.svg",
        brand: "Longchamp",
        type: "Korrektionsbrille",
        gender: "Damen",
    },
    {
        brilleFace: "assets/img/longchamp/produkte/LO2647_Face-515.jpg",
        brilleProfile: "assets/img/longchamp/produkte/LO2647_Profile-515.jpg",
        logo: "assets/img/longchamp/longchamp.svg",
        brand: "Longchamp",
        type: "Korrektionsbrille",
        gender: "Damen",
    },
    {
        brilleFace: "assets/img/longchamp/produkte/LO2670L_Face-001.jpg",
        brilleProfile: "assets/img/longchamp/produkte/LO2670L_Profile-001.jpg",
        logo: "assets/img/longchamp/longchamp.svg",
        brand: "Longchamp",
        type: "Korrektionsbrille",
        gender: "Damen",
    },
    {
        brilleFace: "assets/img/longchamp/produkte/LO2135_Face-773.jpg",
        brilleProfile: "assets/img/longchamp/produkte/LO2135_Profile-773.jpg",
        logo: "assets/img/longchamp/longchamp.svg",
        brand: "Longchamp",
        type: "Korrektionsbrille",
        gender: "Damen",
    },
    {
        brilleFace: "assets/img/longchamp/produkte/LO2668_Face-237.jpg",
        brilleProfile: "assets/img/longchamp/produkte/LO2668_Profile-237.jpg",
        logo: "assets/img/longchamp/longchamp.svg",
        brand: "Longchamp",
        type: "Korrektionsbrille",
        gender: "Damen",
    },
    {
        brilleFace: "assets/img/longchamp/produkte/LO2665_Face-211.jpg",
        brilleProfile: "assets/img/longchamp/produkte/LO2665_Profile-211.jpg",
        logo: "assets/img/longchamp/longchamp.svg",
        brand: "Longchamp",
        type: "Korrektionsbrille",
        gender: "Damen",
    },
    {
        brilleFace: "assets/img/longchamp/produkte/LO2666_Face-707.jpg",
        brilleProfile: "assets/img/longchamp/produkte/LO2666_Profile-707.jpg",
        logo: "assets/img/longchamp/longchamp.svg",
        brand: "Longchamp",
        type: "Korrektionsbrille",
        gender: "Damen",
    },
    {
        brilleFace: "assets/img/longchamp/produkte/LO2669_Face-518.jpg",
        brilleProfile: "assets/img/longchamp/produkte/LO2669_Profile-518.jpg",
        logo: "assets/img/longchamp/longchamp.svg",
        brand: "Longchamp",
        type: "Korrektionsbrille",
        gender: "Damen",
    },

    {
        brilleFace: "assets/img/longchamp/produkte/LO657S_Face-001.jpg",
        brilleProfile: "assets/img/longchamp/produkte/LO657S_Profile-001.jpg",
        logo: "assets/img/longchamp/longchamp.svg",
        brand: "Longchamp",
        type: "Sonnenbrille",
        gender: "Damen",
    },
    {
        brilleFace: "assets/img/longchamp/produkte/LO658S_Face-424.jpg",
        brilleProfile: "assets/img/longchamp/produkte/LO658S_Profile-424.jpg",
        logo: "assets/img/longchamp/longchamp.svg",
        brand: "Longchamp",
        type: "Sonnenbrille",
        gender: "Damen",
    },
    {
        brilleFace: "assets/img/longchamp/produkte/LO670S_Face-421.jpg",
        brilleProfile: "assets/img/longchamp/produkte/LO670S_Profile-421.jpg",
        logo: "assets/img/longchamp/longchamp.svg",
        brand: "Longchamp",
        type: "Sonnenbrille",
        gender: "Damen",
    },
    {
        brilleFace: "assets/img/longchamp/produkte/LO671S_Face-001.jpg",
        brilleProfile: "assets/img/longchamp/produkte/LO671S_Profile-001.jpg",
        logo: "assets/img/longchamp/longchamp.svg",
        brand: "Longchamp",
        type: "Sonnenbrille",
        gender: "Damen",
    },
    {
        brilleFace: "assets/img/longchamp/produkte/LO675S_Face-214.jpg",
        brilleProfile: "assets/img/longchamp/produkte/LO675S_Profile-214.jpg",
        logo: "assets/img/longchamp/longchamp.svg",
        brand: "Longchamp",
        type: "Sonnenbrille",
        gender: "Damen",
    },

    {
        brilleFace: "assets/img/longchamp/LO101S_Face-711.jpg",
        brilleProfile: "assets/img/longchamp/LO101S_Profile-711.jpg",
        logo: "assets/img/longchamp/longchamp.svg",
        brand: "Longchamp",
        type: "Sonnenbrille",
        gender: "Damen",
    },
    {
        brilleFace: "assets/img/longchamp/LO132SL_Face-716.jpg",
        brilleProfile: "assets/img/longchamp/LO132SL_Profile-716.jpg",
        logo: "assets/img/longchamp/longchamp.svg",
        brand: "Longchamp",
        type: "Sonnenbrille",
        gender: "Damen",
    },
    {
        brilleFace: "assets/img/longchamp/LO127S_Face-800.jpg",
        brilleProfile: "assets/img/longchamp/LO127S_Profile-800.jpg",
        logo: "assets/img/longchamp/longchamp.svg",
        brand: "Longchamp",
        type: "Sonnenbrille",
        gender: "Damen",
    },
];
