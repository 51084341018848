const brillen = [
    // Longchamp
	{
		brilleFace: 'assets/img/longchamp/produkte/LO2152_Face.jpg',
		brilleProfile: 'assets/img/longchamp/produkte/LO2152_Profile.jpg',
		logo: 'assets/img/longchamp/longchamp.svg',
		brand: 'Longchamp',
		type: 'Korrektionsbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/longchamp/produkte/LO2153_Face.jpg',
		brilleProfile: 'assets/img/longchamp/produkte/LO2153_Profile.jpg',
		logo: 'assets/img/longchamp/longchamp.svg',
		brand: 'Longchamp',
		type: 'Korrektionsbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/longchamp/produkte/LO2154_Face.jpg',
		brilleProfile: 'assets/img/longchamp/produkte/LO2154_Profile.jpg',
		logo: 'assets/img/longchamp/longchamp.svg',
		brand: 'Longchamp',
		type: 'Korrektionsbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/longchamp/produkte/LO2703_Face.jpg',
		brilleProfile: 'assets/img/longchamp/produkte/LO2703_Profile.jpg',
		logo: 'assets/img/longchamp/longchamp.svg',
		brand: 'Longchamp',
		type: 'Korrektionsbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/longchamp/produkte/LO2704_Face.jpg',
		brilleProfile: 'assets/img/longchamp/produkte/LO2704_Profile.jpg',
		logo: 'assets/img/longchamp/longchamp.svg',
		brand: 'Longchamp',
		type: 'Korrektionsbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/longchamp/produkte/LO2705_Face.jpg',
		brilleProfile: 'assets/img/longchamp/produkte/LO2705_Profile.jpg',
		logo: 'assets/img/longchamp/longchamp.svg',
		brand: 'Longchamp',
		type: 'Korrektionsbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/longchamp/produkte/LO2707_Face.jpg',
		brilleProfile: 'assets/img/longchamp/produkte/LO2707_Profile.jpg',
		logo: 'assets/img/longchamp/longchamp.svg',
		brand: 'Longchamp',
		type: 'Korrektionsbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/longchamp/produkte/LO2709_Face.jpg',
		brilleProfile: 'assets/img/longchamp/produkte/LO2709_Profile.jpg',
		logo: 'assets/img/longchamp/longchamp.svg',
		brand: 'Longchamp',
		type: 'Korrektionsbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/longchamp/produkte/LO2608_Face.jpg',
		brilleProfile: 'assets/img/longchamp/produkte/LO2608_Profile.jpg',
		logo: 'assets/img/longchamp/longchamp.svg',
		brand: 'Longchamp',
		type: 'Korrektionsbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/longchamp/produkte/LO2616_Face.jpg',
		brilleProfile: 'assets/img/longchamp/produkte/LO2616_Profile.jpg',
		logo: 'assets/img/longchamp/longchamp.svg',
		brand: 'Longchamp',
		type: 'Korrektionsbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/longchamp/produkte/LO163S_Face.jpg',
		brilleProfile: 'assets/img/longchamp/produkte/LO163S_Profile.jpg',
		logo: 'assets/img/longchamp/longchamp.svg',
		brand: 'Longchamp',
		type: 'Sonnenbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/longchamp/produkte/LO164S_Face.jpg',
		brilleProfile: 'assets/img/longchamp/produkte/LO164S_Profile.jpg',
		logo: 'assets/img/longchamp/longchamp.svg',
		brand: 'Longchamp',
		type: 'Sonnenbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/longchamp/produkte/LO716S_Face.jpg',
		brilleProfile: 'assets/img/longchamp/produkte/LO716S_Profile.jpg',
		logo: 'assets/img/longchamp/longchamp.svg',
		brand: 'Longchamp',
		type: 'Sonnenbrille',
		gender: 'Damen',
	},
	
	{
		brilleFace: 'assets/img/longchamp/produkte/LO717S_Face.jpg',
		brilleProfile: 'assets/img/longchamp/produkte/LO717S_Profile.jpg',
		logo: 'assets/img/longchamp/longchamp.svg',
		brand: 'Longchamp',
		type: 'Sonnenbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/longchamp/produkte/LO722S_Face.jpg',
		brilleProfile: 'assets/img/longchamp/produkte/LO722S_Profile.jpg',
		logo: 'assets/img/longchamp/longchamp.svg',
		brand: 'Longchamp',
		type: 'Sonnenbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/longchamp/produkte/LO658S_Face.jpg',
		brilleProfile: 'assets/img/longchamp/produkte/LO658S_Profile.jpg',
		logo: 'assets/img/longchamp/longchamp.svg',
		brand: 'Longchamp',
		type: 'Sonnenbrille',
		gender: 'Damen',
	},

	{
		brilleFace: 'assets/img/longchamp/produkte/LO658S_Face.jpg',
		brilleProfile: 'assets/img/longchamp/produkte/LO658S_Profile.jpg',
		logo: 'assets/img/longchamp/longchamp.svg',
		brand: 'Longchamp',
		type: 'Sonnenbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/longchamp/produkte/LO101S_Face.jpg',
		brilleProfile: 'assets/img/longchamp/produkte/LO101S_Profile.jpg',
		logo: 'assets/img/longchamp/longchamp.svg',
		brand: 'Longchamp',
		type: 'Sonnenbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/longchamp/produkte/LO162S_Face.jpg',
		brilleProfile: 'assets/img/longchamp/produkte/LO162S_Profile.jpg',
		logo: 'assets/img/longchamp/longchamp.svg',
		brand: 'Longchamp',
		type: 'Sonnenbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/longchamp/produkte/LO159S_Face.jpg',
		brilleProfile: 'assets/img/longchamp/produkte/LO159S_Profile.jpg',
		logo: 'assets/img/longchamp/longchamp.svg',
		brand: 'Longchamp',
		type: 'Sonnenbrille',
		gender: 'Damen',
	},
	

    // Liu Jo

	{
		brilleFace: 'assets/img/liujo/produkte/LJ2164_Face.jpg',
		brilleProfile: 'assets/img/liujo/produkte/LJ2164_Profile.jpg',
		logo: 'assets/img/liujo/liujo.svg',
		brand: 'Liu Jo',
		type: 'Korrektionsbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/liujo/produkte/LJ2767_Face.jpg',
		brilleProfile: 'assets/img/liujo/produkte/LJ2767_Profile.jpg',
		logo: 'assets/img/liujo/liujo.svg',
		brand: 'Liu Jo',
		type: 'Korrektionsbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/liujo/produkte/LJ2768_Face.jpg',
		brilleProfile: 'assets/img/liujo/produkte/LJ2768_Profile.jpg',
		logo: 'assets/img/liujo/liujo.svg',
		brand: 'Liu Jo',
		type: 'Korrektionsbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/liujo/produkte/LJ2770R_Face.jpg',
		brilleProfile: 'assets/img/liujo/produkte/LJ2770R_Profile.jpg',
		logo: 'assets/img/liujo/liujo.svg',
		brand: 'Liu Jo',
		type: 'Korrektionsbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/liujo/produkte/LJ2765_Face.jpg',
		brilleProfile: 'assets/img/liujo/produkte/LJ2765_Profile.jpg',
		logo: 'assets/img/liujo/liujo.svg',
		brand: 'Liu Jo',
		type: 'Korrektionsbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/liujo/produkte/LJ2769_Face.jpg',
		brilleProfile: 'assets/img/liujo/produkte/LJ2769_Profile.jpg',
		logo: 'assets/img/liujo/liujo.svg',
		brand: 'Liu Jo',
		type: 'Korrektionsbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/liujo/produkte/LJ2165_Face.jpg',
		brilleProfile: 'assets/img/liujo/produkte/LJ2165_Profile.jpg',
		logo: 'assets/img/liujo/liujo.svg',
		brand: 'Liu Jo',
		type: 'Korrektionsbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/liujo/produkte/LJ2772R_Face.jpg',
		brilleProfile: 'assets/img/liujo/produkte/LJ2772R_Profile.jpg',
		logo: 'assets/img/liujo/liujo.svg',
		brand: 'Liu Jo',
		type: 'Korrektionsbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/liujo/produkte/LJ2159_Face.jpg',
		brilleProfile: 'assets/img/liujo/produkte/LJ2159_Profile.jpg',
		logo: 'assets/img/liujo/liujo.svg',
		brand: 'Liu Jo',
		type: 'Korrektionsbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/liujo/produkte/LJ2154_Face.jpg',
		brilleProfile: 'assets/img/liujo/produkte/LJ2154_Profile.jpg',
		logo: 'assets/img/liujo/liujo.svg',
		brand: 'Liu Jo',
		type: 'Korrektionsbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/liujo/produkte/LJ2757_Face.jpg',
		brilleProfile: 'assets/img/liujo/produkte/LJ2757_Profile.jpg',
		logo: 'assets/img/liujo/liujo.svg',
		brand: 'Liu Jo',
		type: 'Korrektionsbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/liujo/produkte/LJ2147_Face.jpg',
		brilleProfile: 'assets/img/liujo/produkte/LJ2147_Profile.jpg',
		logo: 'assets/img/liujo/liujo.svg',
		brand: 'Liu Jo',
		type: 'Korrektionsbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/liujo/produkte/LJ769SR_Face.jpg',
		brilleProfile: 'assets/img/liujo/produkte/LJ769SR_Profile.jpg',
		logo: 'assets/img/liujo/liujo.svg',
		brand: 'Liu Jo',
		type: 'Sonnenbrille',
		gender: 'Damen',
		},
	{
		brilleFace: 'assets/img/liujo/produkte/LJ133S_Face.jpg',
		brilleProfile: 'assets/img/liujo/produkte/LJ133S_Profile.jpg',
		logo: 'assets/img/liujo/liujo.svg',
		brand: 'Liu Jo',
		type: 'Sonnenbrille',
		gender: 'Damen',
		},

	{
		brilleFace: 'assets/img/liujo/produkte/LJ765S_Face.jpg',
		brilleProfile: 'assets/img/liujo/produkte/LJ765S_Profile.jpg',
		logo: 'assets/img/liujo/liujo.svg',
		brand: 'Liu Jo',
		type: 'Sonnenbrille',
		gender: 'Damen',
		},
	{
		brilleFace: 'assets/img/liujo/produkte/LJ152S_Face.jpg',
		brilleProfile: 'assets/img/liujo/produkte/LJ152S_Profile.jpg',
		logo: 'assets/img/liujo/liujo.svg',
		brand: 'Liu Jo',
		type: 'Sonnenbrille',
		gender: 'Damen',
	},
			{
		brilleFace: 'assets/img/liujo/produkte/LJ766S_Face.jpg',
		brilleProfile: 'assets/img/liujo/produkte/LJ766S_Profile.jpg',
		logo: 'assets/img/liujo/liujo.svg',
		brand: 'Liu Jo',
		type: 'Sonnenbrille',
		gender: 'Damen',
		},
	{
		brilleFace: 'assets/img/liujo/produkte/LJ767SR_Face.jpg',
		brilleProfile: 'assets/img/liujo/produkte/LJ767SR_Profile.jpg',
		logo: 'assets/img/liujo/liujo.svg',
		brand: 'Liu Jo',
		type: 'Sonnenbrille',
		gender: 'Damen',
	}, 
			{
		brilleFace: 'assets/img/liujo/produkte/LJ757S_Face.jpg',
		brilleProfile: 'assets/img/liujo/produkte/LJ757S_Profile.jpg',
		logo: 'assets/img/liujo/liujo.svg',
		brand: 'Liu Jo',
		type: 'Sonnenbrille',
		gender: 'Damen',
		},
		{
		brilleFace: 'assets/img/liujo/produkte/LJ759S_Face.jpg',
		brilleProfile: 'assets/img/liujo/produkte/LJ759S_Profile.jpg',
		logo: 'assets/img/liujo/liujo.svg',
		brand: 'Liu Jo',
		type: 'Sonnenbrille',
		gender: 'Damen',
		},
	

   
    // Nike

	{
		brilleFace: 'assets/img/nike/produkte/NK5546_Face.jpg',
		brilleProfile: 'assets/img/nike/produkte/NK5546_Profile.jpg',
		logo: 'assets/img/nike/nike.svg',
		brand: 'Nike',
		type: 'Korrektionsbrille',
		gender: 'Kinder',
		},
		{
		brilleFace: 'assets/img/nike/produkte/NK5591_Face.jpg',
		brilleProfile: 'assets/img/nike/produkte/NK5591_Profile.jpg',
		logo: 'assets/img/nike/nike.svg',
		brand: 'Nike',
		type: 'Korrektionsbrille',
		gender: 'Unisex',
		},
	{
		brilleFace: 'assets/img/nike/produkte/NK6046_Face.jpg',
		brilleProfile: 'assets/img/nike/produkte/NK6046_Profile.jpg',
		logo: 'assets/img/nike/nike.svg',
		brand: 'Nike',
		type: 'Korrektionsbrille',
		gender: 'Herren',
		},
	{
		brilleFace: 'assets/img/nike/produkte/NK6045_Face.jpg',
		brilleProfile: 'assets/img/nike/produkte/NK6045_Profile.jpg',
		logo: 'assets/img/nike/nike.svg',
		brand: 'Nike',
		type: 'Korrektionsbrille',
		gender: 'Herren',
	},

	{
		brilleFace: 'assets/img/nike/produkte/NK7260_Face.jpg',
		brilleProfile: 'assets/img/nike/produkte/NK7260_Profile.jpg',
		logo: 'assets/img/nike/nike.svg',
		brand: 'Nike',
		type: 'Korrektionsbrille',
		gender: 'Unisex',
	},
	{
		brilleFace: 'assets/img/nike/produkte/NK7259_Face.jpg',
		brilleProfile: 'assets/img/nike/produkte/NK7259_Profile.jpg',
		logo: 'assets/img/nike/nike.svg',
		brand: 'Nike',
		type: 'Korrektionsbrille',
		gender: 'Unisex',
	},
	{
		brilleFace: 'assets/img/nike/produkte/NK7046_Face.jpg',
		brilleProfile: 'assets/img/nike/produkte/NK7046_Profile.jpg',
		logo: 'assets/img/nike/nike.svg',
		brand: 'Nike',
		type: 'Korrektionsbrille',
		gender: 'Herren',
	},
	{
		brilleFace: 'assets/img/nike/produkte/NK7047_Face.jpg',
		brilleProfile: 'assets/img/nike/produkte/NK7047_Profile.jpg',
		logo: 'assets/img/nike/nike.svg',
		brand: 'Nike',
		type: 'Korrektionsbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/nike/produkte/Nike_7280_Face.jpg',
		brilleProfile: 'assets/img/nike/produkte/Nike_7280_Profile.jpg',
		logo: 'assets/img/nike/nike.svg',
		brand: 'Nike',
		type: 'Korrektionsbrille',
		gender: 'Herren',
		},
		{
		brilleFace: 'assets/img/nike/produkte/Nike_7090_Face.jpg',
		brilleProfile: 'assets/img/nike/produkte/Nike_7090_Profile.jpg',
		logo: 'assets/img/nike/nike.svg',
		brand: 'Nike',
		type: 'Korrektionsbrille',
		gender: 'Herren',
		},
		{
		brilleFace: 'assets/img/nike/produkte/Nike_5019_Face.jpg',
		brilleProfile: 'assets/img/nike/produkte/Nike_5019_Profile.jpg',
		logo: 'assets/img/nike/nike.svg',
		brand: 'Nike',
		type: 'Korrektionsbrille',
		gender: 'Kinder',
		},
		{
		brilleFace: 'assets/img/nike/produkte/Nike_5509_Face.jpg',
		brilleProfile: 'assets/img/nike/produkte/Nike_5509_Profile.jpg',
		logo: 'assets/img/nike/nike.svg',
		brand: 'Nike',
		type: 'Korrektionsbrille',
		gender: 'Kids',
		},
		{
		brilleFace: 'assets/img/nike/produkte/NKDZ7353_Face.jpg',
		brilleProfile: 'assets/img/nike/produkte/NKDZ7353_Profile.jpg',
		logo: 'assets/img/nike/nike.svg',
		brand: 'Nike',
		type: 'Sonnenbrille',
		gender: 'Unisex',
		},
	{
		brilleFace: 'assets/img/nike/produkte/FB1316_Face.jpg',
		brilleProfile: 'assets/img/nike/produkte/FB1316_Profile.jpg',
		logo: 'assets/img/nike/nike.svg',
		brand: 'Nike',
		type: 'Sonnenbrille',
		gender: 'Damen',
		},
	{
		brilleFace: 'assets/img/nike/produkte/NKDZ7358_Face.jpg',
		brilleProfile: 'assets/img/nike/produkte/NKDZ7358_Profile.jpg',
		logo: 'assets/img/nike/nike.svg',
		brand: 'Nike',
		type: 'Sonnenbrille',
		gender: 'Herren',
		},
	
	{
		brilleFace: 'assets/img/nike/produkte/NKDZ7369_Face.jpg',
		brilleProfile: 'assets/img/nike/produkte/NKDZ7369_Profile.jpg',
		logo: 'assets/img/nike/nike.svg',
		brand: 'Nike',
		type: 'Sonnenbrille',
		gender: 'Unisex',
		},
	
		{
		brilleFace: 'assets/img/nike/produkte/NKDZ7364_Face.jpg',
		brilleProfile: 'assets/img/nike/produkte/NKDZ7364_Profile.jpg',
		logo: 'assets/img/nike/nike.svg',
		brand: 'Nike',
		type: 'Sonnenbrille',
		gender: 'Herren',
		},
		{
			brilleFace: 'assets/img/nike/produkte/NKDZ7363_Face.jpg',
			brilleProfile: 'assets/img/nike/produkte/NKDZ7363_Profile.jpg',
			logo: 'assets/img/nike/nike.svg',
			brand: 'Nike',
			type: 'Sonnenbrille',
			gender: 'Unisex',
		},
		{
			brilleFace: 'assets/img/nike/produkte/NIKE_ADRENALINE_EV1112_Face.jpg',
			brilleProfile: 'assets/img/nike/produkte/NIKE_ADRENALINE_EV1112_Profile.jpg',
			logo: 'assets/img/nike/nike.svg',
			brand: 'Nike',
			type: 'Sonnenbrille',
			gender: 'Herren',
		},
		{
			brilleFace: 'assets/img/nike/produkte/NIKE_RABID_EV1109_Face.jpg',
			brilleProfile: 'assets/img/nike/produkte/NIKE_RABID_EV1109_Profile.jpg',
			logo: 'assets/img/nike/nike.svg',
			brand: 'Nike',
			type: 'Sonnenbrille',
			gender: 'Herren',
		},
		
	
	

    //Lacoste
    {
		brilleFace: 'assets/img/lacoste/produkte/L2286_Face.jpg',
		brilleProfile: 'assets/img/lacoste/produkte/L2286_Profile.jpg',
		logo: 'assets/img/lacoste/lacoste.svg',
		brand: 'Lacoste',
		type: 'Korrektionsbrille',
		gender: 'Herren',
	},
	{
		brilleFace: 'assets/img/lacoste/produkte/L2909_Face.jpg',
		brilleProfile: 'assets/img/lacoste/produkte/L2909_Profile.jpg',
		logo: 'assets/img/lacoste/lacoste.svg',
		brand: 'Lacoste',
		type: 'Korrektionsbrille',
		gender: 'Herren',
	},
	{
		brilleFace: 'assets/img/lacoste/produkte/L2914_Face.jpg',
		brilleProfile: 'assets/img/lacoste/produkte/L2914_Profile.jpg',
		logo: 'assets/img/lacoste/lacoste.svg',
		brand: 'Lacoste',
		type: 'Korrektionsbrille',
		gender: 'Herren',
	},
	{
		brilleFace: 'assets/img/lacoste/produkte/L2913_Face.jpg',
		brilleProfile: 'assets/img/lacoste/produkte/L2913_Profile.jpg',
		logo: 'assets/img/lacoste/lacoste.svg',
		brand: 'Lacoste',
		type: 'Korrektionsbrille',
		gender: 'Herren',
	},
	{
		brilleFace: 'assets/img/lacoste/produkte/L2907_Face.jpg',
		brilleProfile: 'assets/img/lacoste/produkte/L2907_Profile.jpg',
		logo: 'assets/img/lacoste/lacoste.svg',
		brand: 'Lacoste',
		type: 'Korrektionsbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/lacoste/produkte/L2918_Face.jpg',
		brilleProfile: 'assets/img/lacoste/produkte/L2918_Profile.jpg',
		logo: 'assets/img/lacoste/lacoste.svg',
		brand: 'Lacoste',
		type: 'Korrektionsbrille',
		gender: 'Herren',
	},
	{
		brilleFace: 'assets/img/lacoste/produkte/L2288_Face.jpg',
		brilleProfile: 'assets/img/lacoste/produkte/L2288_Profile.jpg',
		logo: 'assets/img/lacoste/lacoste.svg',
		brand: 'Lacoste',
		type: 'Korrektionsbrille',
		gender: 'Herren',
	},
	{
		brilleFace: 'assets/img/lacoste/produkte/L982S_Face.jpg',
		brilleProfile: 'assets/img/lacoste/produkte/L982S_Profile.jpg',
		logo: 'assets/img/lacoste/lacoste.svg',
		brand: 'Lacoste',
		type: 'Sonnenbrille',
		gender: 'Herren',
	},
	{
		brilleFace: 'assets/img/lacoste/produkte/L986S_Face.jpg',
		brilleProfile: 'assets/img/lacoste/produkte/L986S_Profile.jpg',
		logo: 'assets/img/lacoste/lacoste.svg',
		brand: 'Lacoste',
		type: 'Sonnenbrille',
		gender: 'Herren',
		},
	{
		brilleFace: 'assets/img/lacoste/produkte/L992S_Face.jpg',
		brilleProfile: 'assets/img/lacoste/produkte/L992S_Profile.jpg',
		logo: 'assets/img/lacoste/lacoste.svg',
		brand: 'Lacoste',
		type: 'Sonnenbrille',
		gender: 'Herren',
	},
		
	{
		brilleFace: 'assets/img/lacoste/produkte/L987S_Face.jpg',
		brilleProfile: 'assets/img/lacoste/produkte/L987S_Profile.jpg',
		logo: 'assets/img/lacoste/lacoste.svg',
		brand: 'Lacoste',
		type: 'Sonnenbrille',
		gender: 'Herren',
	},
	{
		brilleFace: 'assets/img/lacoste/produkte/L983S_Face.jpg',
		brilleProfile: 'assets/img/lacoste/produkte/L983S_Profile.jpg',
		logo: 'assets/img/lacoste/lacoste.svg',
		brand: 'Lacoste',
		type: 'Sonnenbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/lacoste/produkte/L860SE_Face.jpg',
		brilleProfile: 'assets/img/lacoste/produkte/L860SE_Profile.jpg',
		logo: 'assets/img/lacoste/lacoste.svg',
		brand: 'Lacoste',
		type: 'Sonnenbrille',
		gender: 'Herren',
		},
	{
		brilleFace: 'assets/img/lacoste/produkte/L882S_Face.jpg',
		brilleProfile: 'assets/img/lacoste/produkte/L882S_Profile.jpg',
		logo: 'assets/img/lacoste/lacoste.svg',
		brand: 'Lacoste',
		type: 'Sonnenbrille',
		gender: 'Herren',
	}, 
	{
		brilleFace: 'assets/img/lacoste/produkte/L916S_Face.jpg',
		brilleProfile: 'assets/img/lacoste/produkte/L916S_Profile.jpg',
		logo: 'assets/img/lacoste/lacoste.svg',
		brand: 'Lacoste',
		type: 'Sonnenbrille',
		gender: 'Unisex',
	},
	{
		brilleFace: 'assets/img/lacoste/produkte/L2707_Face.jpg',
		brilleProfile: 'assets/img/lacoste/produkte/L2707_Profile.jpg',
		logo: 'assets/img/lacoste/lacoste.svg',
		brand: 'Lacoste',
		type: 'Korrektionsbrille',
		gender: 'Male',
	},
	{
		brilleFace: 'assets/img/lacoste/produkte/L3647_Face.jpg',
		brilleProfile: 'assets/img/lacoste/produkte/L3647_Profile.jpg',
		logo: 'assets/img/lacoste/lacoste.svg',
		brand: 'Lacoste',
		type: 'Korrektionsbrille',
		gender: 'Kinder',
	},

	{
		brilleFace: 'assets/img/lacoste/produkte/L2271_Face.jpg',
		brilleProfile: 'assets/img/lacoste/produkte/L2271_Profile.jpg',
		logo: 'assets/img/lacoste/lacoste.svg',
		brand: 'Lacoste',
		type: 'Korrektionsbrille',
		gender: 'Herren',
	},
	{
		brilleFace: 'assets/img/lacoste/produkte/L2279_Face.jpg',
		brilleProfile: 'assets/img/lacoste/produkte/L2279_Profile.jpg',
		logo: 'assets/img/lacoste/lacoste.svg',
		brand: 'Lacoste',
		type: 'Korrektionsbrille',
		gender: 'Herren',
	},
		
	{
		brilleFace: 'assets/img/lacoste/produkte/L2876_Face.jpg',
		brilleProfile: 'assets/img/lacoste/produkte/L2876_Profile.jpg',
		logo: 'assets/img/lacoste/lacoste.svg',
		brand: 'Lacoste',
		type: 'Korrektionsbrille',
		gender: 'Herren',
	},
	

    // Calvin Klein

	{
		brilleFace: 'assets/img/calvinklein/produkte/CK22125TC_Face.jpg',
		brilleProfile: 'assets/img/calvinklein/produkte/CK22125TC_Profile.jpg',
		logo: 'assets/img/calvinklein/calvinklein.svg',
		brand: 'Calvin Klein',
		type: 'Korrektionsbrille',
		gender: 'Unisex',
	},
	{
		brilleFace: 'assets/img/calvinklein/produkte/CK22543_Face.jpg',
		brilleProfile: 'assets/img/calvinklein/produkte/CK22543_Profile.jpg',
		logo: 'assets/img/calvinklein/calvinklein.svg',
		brand: 'Calvin Klein',
		type: 'Korrektionsbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/calvinklein/produkte/CK22117_Face.jpg',
		brilleProfile: 'assets/img/calvinklein/produkte/CK22117_Profile.jpg',
		logo: 'assets/img/calvinklein/calvinklein.svg',
		brand: 'Calvin Klein',
		type: 'Korrektionsbrille',
		gender: 'Unisex',
	},
	{
		brilleFace: 'assets/img/calvinklein/produkte/CK22540_Face.jpg',
		brilleProfile: 'assets/img/calvinklein/produkte/CK22540_Profile.jpg',
		logo: 'assets/img/calvinklein/calvinklein.svg',
		brand: 'Calvin Klein',
		type: 'Korrektionsbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/calvinklein/produkte/CK22118_Face.jpg',
		brilleProfile: 'assets/img/calvinklein/produkte/CK22118_Profile.jpg',
		logo: 'assets/img/calvinklein/calvinklein.svg',
		brand: 'Calvin Klein',
		type: 'Korrektionsbrille',
		gender: 'Herren',
	}, 
	{
		brilleFace: 'assets/img/calvinklein/produkte/CK22547_Face.jpg',
		brilleProfile: 'assets/img/calvinklein/produkte/CK22547_Profile.jpg',
		logo: 'assets/img/calvinklein/calvinklein.svg',
		brand: 'Calvin Klein',
		type: 'Korrektionsbrille',
		gender: 'Herren',
	},
	{
		brilleFace: 'assets/img/calvinklein/produkte/CK22542_Face.jpg',
		brilleProfile: 'assets/img/calvinklein/produkte/CK22542_Profile.jpg',
		logo: 'assets/img/calvinklein/calvinklein.svg',
		brand: 'Calvin Klein',
		type: 'Korrektionsbrille',
		gender: 'Herren',
	},
	{
		brilleFace: 'assets/img/calvinklein/produkte/CK22119_Face.jpg',
		brilleProfile: 'assets/img/calvinklein/produkte/CK22119_Profile.jpg',
		logo: 'assets/img/calvinklein/calvinklein.svg',
		brand: 'Calvin Klein',
		type: 'Korrektionsbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/calvinklein/produkte/CK19119_Face.jpg',
		brilleProfile: 'assets/img/calvinklein/produkte/CK19119_Profile.jpg',
		logo: 'assets/img/calvinklein/calvinklein.svg',
		brand: 'Calvin Klein',
		type: 'Korrektionsbrille',
		gender: 'Unisex',
	},
	{
		brilleFace: 'assets/img/calvinklein/produkte/CK5460_Face.jpg',
		brilleProfile: 'assets/img/calvinklein/produkte/CK5460_Profile.jpg',
		logo: 'assets/img/calvinklein/calvinklein.svg',
		brand: 'Calvin Klein',
		type: 'Korrektionsbrille',
		gender: 'Unisex',
	},
	{
		brilleFace: 'assets/img/calvinklein/produkte/CK22508_Face.jpg',
		brilleProfile: 'assets/img/calvinklein/produkte/CK22508_Profile.jpg',
		logo: 'assets/img/calvinklein/calvinklein.svg',
		brand: 'Calvin Klein',
		type: 'Korrektionsbrille',
		gender: 'Herren',
	},
	{
		brilleFace: 'assets/img/calvinklein/produkte/CK19569_Face.jpg',
		brilleProfile: 'assets/img/calvinklein/produkte/CK19569_Profile.jpg',
		logo: 'assets/img/calvinklein/calvinklein.svg',
		brand: 'Calvin Klein',
		type: 'Korrektionsbrille',
		gender: 'Herren',
		},
	{
		brilleFace: 'assets/img/calvinklein/produkte/CK22114S_Face.jpg',
		brilleProfile: 'assets/img/calvinklein/produkte/CK22114S_Profile.jpg',
		logo: 'assets/img/calvinklein/calvinklein.svg',
		brand: 'Calvin Klein',
		type: 'Sonnenbrille',
		gender: 'Herren',
	},
	{
		brilleFace: 'assets/img/calvinklein/produkte/CK22515S_Face.jpg',
		brilleProfile: 'assets/img/calvinklein/produkte/CK22515S_Profile.jpg',
		logo: 'assets/img/calvinklein/calvinklein.svg',
		brand: 'Calvin Klein',
		type: 'Sonnenbrille',
		gender: 'Unisex',
	},
	{
		brilleFace: 'assets/img/calvinklein/produkte/CK22516S_Face.jpg',
		brilleProfile: 'assets/img/calvinklein/produkte/CK22516S_Profile.jpg',
		logo: 'assets/img/calvinklein/calvinklein.svg',
		brand: 'Calvin Klein',
		type: 'Sonnenbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/calvinklein/produkte/CK22520S_Face.jpg',
		brilleProfile: 'assets/img/calvinklein/produkte/CK22520S_Profile.jpg',
		logo: 'assets/img/calvinklein/calvinklein.svg',
		brand: 'Calvin Klein',
		type: 'Sonnenbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/calvinklein/produkte/CK22533S_Face.jpg',
		brilleProfile: 'assets/img/calvinklein/produkte/CK22533S_Profile.jpg',
		logo: 'assets/img/calvinklein/calvinklein.svg',
		brand: 'Calvin Klein',
		type: 'Sonnenbrille',
		gender: 'Unisex',
	},
	{
		brilleFace: 'assets/img/calvinklein/produkte/CK22531S_Face.jpg',
		brilleProfile: 'assets/img/calvinklein/produkte/CK22531S_Profile.jpg',
		logo: 'assets/img/calvinklein/calvinklein.svg',
		brand: 'Calvin Klein',
		type: 'Sonnenbrille',
		gender: 'Damen',
	},
	{
		brilleFace: 'assets/img/calvinklein/produkte/CK22115S_Face.jpg',
		brilleProfile: 'assets/img/calvinklein/produkte/CK22115S_Profile.jpg',
		logo: 'assets/img/calvinklein/calvinklein.svg',
		brand: 'Calvin Klein',
		type: 'Sonnenbrille',
		gender: 'Herren',
	},
	{
		brilleFace: 'assets/img/calvinklein/produkte/CK22532S_Face.jpg',
		brilleProfile: 'assets/img/calvinklein/produkte/CK22532S_Profile.jpg',
		logo: 'assets/img/calvinklein/calvinklein.svg',
		brand: 'Calvin Klein',
		type: 'Sonnenbrille',
		gender: 'Damen',
		},
];

const brandbanner = [
    {
        brandbanner: {
            image: "assets/img/longchamp/keyvisual.jpg",
            brand: "Longchamp",
            logo: "assets/img/longchamp/longchamp.svg",
            link: "longchamp",
        },
    },
    {
        brandbanner: {
            image: "assets/img/liujo/keyvisual.jpg",
            brand: "Liu Jo",
            logo: "assets/img/liujo/liujo.svg",
            link: "liujo",
        },
    },
    {
        brandbanner: {
            image: "assets/img/nike/keyvisual.jpg",
            brand: "Nike",
            logo: "assets/img/nike/nike.svg",
            link: "nike",
        },
    },
    {
        brandbanner: {
            image: "assets/img/lacoste/keyvisual.jpg",
            brand: "Lacoste",
            logo: "assets/img/lacoste/lacoste.svg",
            link: "lacoste",
        },
    },
    {
        brandbanner: {
            image: "assets/img/calvinklein/keyvisual.jpg",
            brand: "Calvin Klein",
            logo: "assets/img/calvinklein/calvinklein.svg",
            link: "calvinklein",
        },
    },
];

// ============================================
// Get Filter
// ============================================

var $_GET = {};
if (document.location.toString().indexOf("?") !== -1) {
    var query = document.location
        .toString()
        // get the query string
        .replace(/^.*?\?/, "")
        // and remove any existing hash string (thanks, @vrijdenker)
        .replace(/#.*$/, "")
        .split("&");

    for (var i = 0, l = query.length; i < l; i++) {
        var aux = decodeURIComponent(query[i]).split("=");
        $_GET[aux[0]] = aux[1];
    }
}

let notAwailableBrands
if ($_GET["brands"]) {
	notAwailableBrands = $_GET["brands"].split(",")
} else {
	notAwailableBrands = []
}

// Alle 12 Brillen einen Banner einsetzen

let brillenOhneGet;
let bannerOhneGet;

function brillenConcatBanner() {
    let banner = 20;
    let bannerIndex = 0;

    brillenOhneGet = brillen.filter(
        (b) =>
            notAwailableBrands.indexOf(
                b.brand.toLowerCase().split(" ").join("")
            ) <= -1
    );
    // console.log(brillenOhneGet)

    bannerOhneGet = brandbanner.filter(
        (b) =>
            notAwailableBrands.indexOf(
                b.brandbanner.brand.toLowerCase().split(" ").join("")
            ) <= -1
    );

    // console.log(bannerOhneGet)

    brillenOhneGet.map((brille, index) => {
        if (index === banner && bannerIndex < bannerOhneGet.length) {
            brillenOhneGet.splice(banner, 0, bannerOhneGet[bannerIndex]);
            banner += 21;
            bannerIndex++;
        }
        if (
            brillenOhneGet.length === banner &&
            bannerIndex <= bannerOhneGet.length
        ) {
            brillenOhneGet.push(bannerOhneGet[bannerIndex]);
        }
    });
}

brillenConcatBanner();
